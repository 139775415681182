// 3rd Party
import { useState, useEffect } from 'react'
import Link from 'next/link'

// Components
import Icon from '@/components/Icon/Icon'
// import MobileMenu from './Mobilemenu'
import CartButton from '../Common/CartButton'
import AccountButton from '../Common/AccountButton'
import MobileSearch from './MobileSearch'
import dynamic from 'next/dynamic'

const MobileMenu = dynamic(() => import('./Mobilemenu').then((mod) => mod.default), {
  loading: () => null,
  ssr: false,
})

// Context
import { useMobileSearchContext } from '@/components/context/MobileSearchContext'

const MobileHeader = ({
  cart,
  cartModalViewHandler,
  customer,
  hasCart,
  hasLogin,
  hideSearch = false,
  isOpenModal,
  modalViewHandler,
  preview,
  searchPlaceholderText,
  signInModalHandler,
  subMenu,
  toggleMenu,
  showPromobar,
}) => {
  const { mobileSearch } = useMobileSearchContext()

  /*   const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener('scroll', onScroll, { passive: true })

    return () => window.removeEventListener('scroll', onScroll, { passive: true })
  }, []) */

  const {
    accountLinkMobile,
    contentBlocks,
    landscapeCta,
    moduleFooter,
    topLevelLinks,
    wishlistLinkMobile,
  } = subMenu || {}

  return (
    <>
      <div className={`UnfixedHeader lg:hidden block top-0 z-20`}>
        {showPromobar && showPromobar}
        <div className="container flex flex-row items-center justify-between pb-[20px] h-[58px] pt-[20px]">
          <div
            aria-label="Menu"
            className="flex flex-row items-center"
            onClick={modalViewHandler}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                modalViewHandler()
              }
            }}
            role="button"
            tabIndex={0}
            id="mobile-menu-button"
          >
            {!isOpenModal && (
              <Icon
                aria-hidden="true"
                alt="Menu button"
                focusable="false"
                className={`cursor-pointer fill-white`}
                viewBox={`0 0 25 25`}
                size={22}
                icon={'menu'}
              />
            )}
          </div>
          <Link href={'/'}>
            <a className="LogoContainer" aria-label="SITKA">
              {hideSearch ? (
                <Icon
                  className={`cursor-pointer fill-white`}
                  viewBox={`0 0 110 14`}
                  width={110}
                  height={14}
                  alt="SITKA logo"
                  icon="logo-type-white"
                />
              ) : (
                <Icon
                  className={`cursor-pointer fill-white`}
                  viewBox={`0 0 142 50`}
                  width={83}
                  height={50}
                  alt="SITKA logo"
                  icon="logo-white"
                />
              )}
            </a>
          </Link>
          {(hasCart || hasLogin) && !isOpenModal && (
            <div className="flex gap-[24px]">
              {hasLogin && <AccountButton customer={customer} handleClick={signInModalHandler} />}
              {hasCart && <CartButton cart={cart} cartModalViewHandler={cartModalViewHandler} />}
            </div>
          )}
          {(!hasCart || !hasLogin) && (
            <div className="flex gap-[24px]">
              {!hasLogin && (
                <div className="w-[26px] h-[26px] rounded-full animate-pulse bg-gray-200" />
              )}
              {!hasCart && (
                <div className="w-[26px] h-[26px] rounded-full animate-pulse bg-gray-200" />
              )}
            </div>
          )}
          {isOpenModal && (
            <div
              aria-label="Menu"
              className="flex flex-row items-center"
              id="mobile-close-button"
              onClick={modalViewHandler}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  modalViewHandler()
                }
              }}
              role="button"
              tabIndex={0}
            >
              <Icon
                aria-hidden="true"
                alt="Menu button"
                focusable="false"
                className={`cursor-pointer fill-white`}
                viewBox={`0 0 25 25`}
                size={22}
                icon={'close'}
              />
            </div>
          )}
        </div>

        {!hideSearch && (
          <MobileSearch
            id={'mobile-search-outer'}
            isOpenModal={false}
            searchPlaceholderText={searchPlaceholderText}
            tabIndex={isOpenModal ? -1 : 0}
          />
        )}

        <MobileMenu
          accountLinkMobile={accountLinkMobile}
          customer={customer}
          data={contentBlocks?.items}
          isOpened={isOpenModal}
          isSearchOpen={!!mobileSearch?.requestQuery}
          landscapeCta={landscapeCta}
          mobileOnlyFooterItems={moduleFooter?.items}
          preview={preview}
          searchPlaceholderText={searchPlaceholderText}
          toggleMenu={toggleMenu}
          topLevelLinks={topLevelLinks?.items}
          wishlistLinkMobile={wishlistLinkMobile}
          showPromobar={showPromobar}
        />
      </div>

      <style jsx>
        {`
          .UnfixedHeader {
            position: fixed;
            width: 100vw;
            background-color: #111111 !important};
          }

          .LogoContainer {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
          }
        `}
      </style>
    </>
  )
}

export default MobileHeader
